<template>
    <data-loading-animation v-if="dataLoading"/>
    <div v-else class="container card bs-4">
        <div class="fl-te-c">
            <h4 class="mb-3">Bull Details</h4>
            <btn size="sm" text="Back" @click="$router.push('/bulls/')"/>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <img v-if="details.file" style="min-height: 27rem;" :src="'/upload/' + details.file" alt=""
                     class="m-0 p-0 w-35v round-3 of-h">
                <img v-else style="min-height: 27rem;" src="../../assets/img/green.png" alt=""
                     class="m-0 p-0 w-35v round-3 of-h">
            </div>
            <div v-if="details" class="col-lg-6">
                <h2 v-if="details.name" class="text-uppercase text-primary oswald-600 mb-4">{{ details.name }}</h2>
                <h2 v-else class="text-uppercase text-primary oswald-600 mb-4">Bull - {{ details.id }}</h2>
                <div class="row">
                    <div class="col mb-3">
                        <p class="mb-0 font-weight-bold">Bull No.</p>
                        <p v-if="details.bullno" class="mb-0 ml-1">{{ details.bullno }}</p>
                        <p v-else class="mb-0 ml-1">Nil</p>
                    </div>
                    <div class="col mb-3">
                        <p class="mb-0 font-weight-bold">Breed</p>
                        <p v-if="details.breed" class="ml-1 mb-0">{{ details.breed.name }}</p>
                        <p v-else class="mb-0 ml-1">Nil</p>
                    </div>
                    <div class="col mb-3">
                        <p class="mb-0 font-weight-bold">Species</p>
                        <p v-if="details.species" class="ml-1 mb-0">{{ details.species.name }}</p>
                        <p v-else class="mb-0 ml-1">Nil</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col mb-3">
                        <p class="mb-0 font-weight-bold">Sire No.</p>
                        <p v-if="details.sirenumber" class="ml-1 mb-0">{{ details.sirenumber }}</p>
                        <p v-else class="mb-0 ml-1">Nil</p>
                    </div>
                    <div class="col mb-3">
                        <p class="mb-0 font-weight-bold">UID</p>
                        <p v-if="details.uid" class="ml-1 mb-0">{{ details.uid }}</p>
                        <p v-else class="mb-0 ml-1">Nil</p>
                    </div>
                    <div class="col mb-3">
                        <p class="mb-0 font-weight-bold">Date of Birth</p>
                        <p v-if="details.dob" class="ml-1 mb-0">{{ details.dob }}</p>
                        <p v-else class="mb-0 ml-1">Nil</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col mb-3">
                        <p class="mb-0 font-weight-bold">Dams No.</p>
                        <p v-if="details.damnumber" class="ml-1 mb-0">{{ details.damnumber }}</p>
                        <p v-else class="mb-0 ml-1">Nil</p>
                    </div>
                    <div class="col mb-3">
                        <p class="mb-0 font-weight-bold">Dams Yeild</p>
                        <p v-if="details.dam_yeild" class="ml-1 mb-0">{{ details.dam_yeild }}</p>
                        <p v-else class="mb-0 ml-1">Nil</p>
                    </div>
                    <div class="col mb-3">
                        <p class="mb-0 font-weight-bold">Sire's Daughters Yield</p>
                        <p v-if="details.sire_daughter_yield" class="ml-1 mb-0">{{ details.sire_daughter_yield }}</p>
                        <p v-else class="mb-0 ml-1">Nil</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col mb-3">
                        <p class="mb-0 font-weight-bold">Milk Fat %</p>
                        <p v-if="details.milkfat" class="ml-1 mb-0">{{ details.milkfat }}</p>
                        <p v-else class="mb-0 ml-1">Nil</p>
                    </div>
                    <div class="col mb-3">
                        <p class="mb-0 font-weight-bold">Milk Protien</p>
                        <p v-if="details.milkprotein" class="ml-1 mb-0">{{ details.milkprotein }}</p>
                        <p v-else class="mb-0 ml-1">Nil</p>
                    </div>
                    <div class="col mb-3">
                        <p class="mb-0 font-weight-bold">Breeding Value</p>
                        <p v-if="details.breeding_value" class="ml-1 mb-0">{{ details.breeding_value }}</p>
                        <p v-else class="mb-0 ml-1">Nil</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4 mb-3">
                        <p class="mb-0 font-weight-bold">Source</p>
                        <p v-if="details.source" class="ml-1 mb-0">{{ details.source }}</p>
                        <p v-else class="mb-0 ml-1">Nil</p>
                    </div>
                    <div class="col-4 mb-3">
                        <p class="mb-0 font-weight-bold">INAPH Code</p>
                        <p v-if="details.inaph_code" class="ml-1 mb-0">{{ details.inaph_code }}</p>
                        <p v-else class="mb-0 ml-1">Nil</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col mb-3">
                        <p class="mb-0 font-weight-bold">Available District</p>
                        <div v-if="details.district">
                            <span v-for="(i, index) in details.district" v-bind:key="i">
                                <span v-if="index !== (details.district.length - 1)">{{ i + ' , ' }}</span>
                                <span v-else>{{ i }}</span>
                            </span>
                        </div>
                        <p v-else class="mb-0 ml-1">Nil</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../data/urls';

export default {
    name: 'BullInfo',
    data () {
        return {
            dataLoading: false,
            id: this.$route.params.id,
            details: ''
        };
    },
    mounted () {
        this.loadDetails();
    },
    methods: {
        async loadDetails () {
            const that = this;
            that.dataLoading = true;
            const response = await axios.form(urls.admin.bulls.details, { id: that.id });
            const json = response.data;
            if (json.success === true) {
                that.details = json.object;
                if (that.details.district) {
                    that.details.district = that.details.district.split(',');
                }
            } else {
                that.$notify('Details not found, Please try again later.', 'No Data Found', {
                    type: 'info'
                });
            }
            that.dataLoading = false;
        }
    }
};
</script>

<style scoped lang="scss">

</style>
